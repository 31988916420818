import axios from "axios";
const url = "https://v2.api.bookedsolid.co.uk";
// const url = 'http://localhost:5002';
let client_id = localStorage.client_id || '';

// Modify alert
window.alert = function (message) {
  const alert = document.getElementById('alert');
  const hide = () => alert.style.display = 'none';
  document.getElementById('alert-text').innerText = message;
  alert.style.display = 'block';
  document.addEventListener("keyup", function (event) {
    if (event.keyCode === 13) hide();
  });
  setTimeout(hide, 6000);
}

/***********  Admin account functions ***********/

// Login a user
async function login(email, password) {
  const data = {
    email,
    password,
  };
  // send request to server
  const response = await axios.post(`${url}/admin/login`, data)
    .catch((err) => { alert(err?.response?.data?.message || 'Incorrect email or password'); });
  if (response?.status == 200) {
    // if successful, add session to localStorage
    localStorage.adminSession = response.data.token;
    sessionStorage.adminSession = response.data.token;
    localStorage.name = response.data.resName;
    localStorage.email = response.data.resEmail;
    localStorage.client_id = response.data.client_id;
    client_id = response.data.client_id;
    localStorage.registrationDate = response.data.registrationDate;
    window.dispatchEvent(new Event('settings_updated'));
    window.location.href = "/";
  } else {
    return false;
  }
}

// Login a user
async function register(name, email, password, integration) {
  const data = {
    name,
    email,
    password,
    integration
  };
  // send request to server
  const response = await axios.post(`${url}/client/register`, data)
    .catch((err) => { alert(err?.response?.data?.message || 'Registration failed. Please try again later, or email contact@bookedsolid.co.uk'); });
  if (response?.status == 200) {
    // if successful, add session to localStorage
    localStorage.adminSession = response.data.token;
    sessionStorage.adminSession = response.data.token;
    localStorage.name = response.data.resName;
    localStorage.email = response.data.resEmail;
    localStorage.client_id = response.data.client_id;
    client_id = response.data.client_id;
    window.dispatchEvent(new Event('settings_updated'));
    return true;
  } else {
    return false;
  }
}

// Create a new site
async function createSite(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post('/site/', data);
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error creating site');
    return false;
  };
}

// Update a site
async function updateSite(id, data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(`/site/${id}`, data);
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error updating site');
    return false;
  }
}

// Get client's sites
async function getSites() {
  const response = await axios.get(`${url}/site/${client_id}`);
  return response?.data?.sites || false;
}

// Get client settings
async function getClientSettings() {
  if (!client_id) return false;
  const response = await axios.get(`${url}/client/settings/${client_id}`,);
  return response?.data?.settings || false;
}

// Update client settings
async function updateClientSettings(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(`/client/settings/${client_id}`, data);
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error updating settings');
    return false;
  }
}

// Check Stripe keys exist
async function checkIntegrations() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/client/integrations`);
  console.log(response.data)
  return response?.data?.integrations || false;
}

// Update Stripe keys
async function updateIntegrations(integrations) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/client/integrations`, integrations);
  if (response?.data?.success) return response?.data;
  else {
    alert(response?.data?.message || 'Error updating settings');
    return false;
  }
}


// Request access to AI
async function requestAIAccess(feature) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const data = { feature };
  const response = await instance.post(`/client/ai/request`, data);
  if (response?.data?.success) return true;
  else {
    alert(response?.data?.message || 'Error requesting access');
    return false;
  }
}

/***********  Check in functions ***********/

// Get the week's bookings for a single Pod by pod_id
async function getBookings(site, week) {
  const response = await axios.get(`${url}/admin/bookings/site/${site}?week=${week}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.adminSession}`
      },
    }
  );
  return response?.data?.bookings;
}

async function getAttendees(bookable_id) {
  const response = await axios.get(`${url}/admin/bookings/attendees/${bookable_id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.adminSession}`
      },
    }
  );
  return response?.data?.attendees;
}

async function markAttendance(booking_id, attendance, classpassBookingId) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(
    '/admin/user/attendance',
    {
      booking_id,
      attendance,
      classpassBookingId
    }
  )
    .catch(err => {
      alert('Error updating attendance');
      console.log(err);
    });

  if (response?.data?.success == true) {
    return response.data;
  }
}

// Edit a booking's note
async function addNote(booking_id, note) {
  const newNote = prompt("Enter new note", note || "");
  if (!newNote || newNote == note) return note;

  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(
    '/admin/user/note',
    {
      booking_id,
      note: newNote
    }
  )
    .catch(err => {
      alert('Error updating note');
      console.log(err);
    });

  console.log(response?.data?.success);
  if (response?.data?.success == true) {
    return newNote;
  }
}

/***********  User functions ***********/

// Find user by email
async function findUser(email) {
  if (!email) alert("Please enter a valid email");
  else {
    const instance = axios.create({
      baseURL: `${url}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.adminSession}`
      },
    });
    const response = await instance.get(
      `/admin/user/${email}`
    );
    // console.log(response);
    if (response?.data?.success == true) return response?.data?.user;
  }
}

// Search for users by name or email
async function searchUsers(query) {
  if (!query) alert("Please enter a valid search term");
  else {
    const instance = axios.create({
      baseURL: `${url}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.adminSession}`
      },
    });
    const response = await instance.get(
      `/admin/users/${query}`
    );
    // console.log(response);
    if (response?.data?.success == true) return response?.data?.results;
    else return false;
  }
}

// Add a credit package to a user
async function addPackage(user_id, credit_package) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const data = {
    user_id,
    package: credit_package
  }
  const response = await instance.put(`/admin/user/packages/add`, data);
  if (response?.data?.success) return true;
  else return false;
}

// Remove a credit package from a user
async function removePackage(user_id, package_id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const data = {
    user_id,
    package_id
  }
  const response = await instance.put(`/admin/user/packages/remove`, data);
  if (response?.data?.success) return true;
  else return false;
}

// Add/remove a visits to a user
async function addVisits(visits, user_id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const data = {
    visits,
    user_id
  }
  const response = await instance.put(`/admin/user/visits`, data);
  if (response?.data?.success) return true;
  else return false;
}

// Update a user's profile info
async function updateUser(user_id, item, update) {
  if (user_id == client_id) {
    alert("You cannot change your own profile");
    return false;
  }
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const data = {
    user_id,
    item,
    update
  }
  const response = await instance.put(`/admin/user/update`, data);
  if (response?.data?.success) return true;
  else return false;
}

// Toggle a user's subscription status
async function toggleSubscription(user_id, status, site) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(`/admin/user/subscription`, { user_id, status, site });
  return response?.data?.user;
}

// Toggle a user/pt's verification status
async function toggleVerification(user_id, status) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(`/admin/user/verify`, { user_id, status });
  return response?.data?.user;
}

// Toggle a user's admin status
async function toggleAdmin(user_id, status) {
  if (user_id == client_id) {
    alert("You cannot change your own admin status");
    return false;
  }
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.put(`/admin/user/admin`, { user_id, status })
    .catch(err => alert(err.response?.data?.message || 'Permission denied'));
  return response?.data?.user;
}

/***********  Management functions ***********/

// Get data for analytics page
async function getAnalytics(site_id, start, end) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });

  const response = await instance.get(`/admin/analytics/${site_id}?start=${start}&end=${end}`);
  console.log(response.data)
  return response.data?.data || false;
}

async function createSchedule(site_id, schedule) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.post(`/site/${site_id}/schedule`, { schedule });
  if (response?.data?.success) return response.data.id;
  else return false;
}

async function deleteSchedule(site_id, schedule_id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.delete(`/site/${site_id}/schedule/${schedule_id}`);
  if (response?.data?.success) return true;
  else return false;
}

async function getSchedule(site_id, week) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });

  const response = await instance.get(`/admin/site/${site_id}/bookables/${week}`)
    .catch(err => console.log(err));
  console.log(response);
  const schedule = response?.data?.schedule;
  if (schedule) return schedule;
  else return false;
}

async function createService(schedule_id, service) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.post(`/site/${schedule_id}/service`, { service });
  if (response?.data?.success) return response.data.schedule;
  else return false;
}

async function deleteService(schedule_id, service_id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.delete(`/site/${schedule_id}/service/${service_id}`);
  if (response?.data?.success) return true;
  else return false;
}

async function setOpeningTimes(schedule_id, data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.put(`/site/${schedule_id}/opening`, data);
  if (response?.data?.success) return response.data.schedule;
  else return false;
}

async function addClosure(schedule_id, closure) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.put(`/site/${schedule_id}/closure`, closure);
  if (response?.data?.success) return response.data.schedule;
  else return false;
}

async function addBookables(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });

  const response = await instance.put('/admin/bookables/add', data);
  if (response?.data?.success) return true;
  else return false;
}

async function removeBookables(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });

  const response = await instance.put('/admin/bookables/remove', data);
  if (response?.data?.success) return true;
  else return false;
}

async function editBookable(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.put('/admin/bookables/edit', data);
  if (response?.data?.success) return response?.data?.bookable;
  else return false;
}

async function exportBookings(site, start, end) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.get(`/admin/export/bookings?start=${start}&end=${end}&site_id=${site}`);
  if (response?.data?.success) return response?.data?.bookings;
  else return false;
};

async function getMessages() {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.get(`/admin/messages`).catch(err => console.log(err));
  console.log(response)
  return response.data.messages;
}

async function addData(message, model) {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.post(`/admin/data`, { message, model });
  return response.data.success;
}

async function getData() {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.get(`/admin/data`);
  console.log(response.data)
  return response.data.data;
}

async function removeData(data) {
  const instance = axios.create({
    baseURL: "http://localhost:5002",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.put(`/admin/data`, { data });
  return response.data.data;
}

/***********  Price functions ***********/

async function getPrices() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.get('/admin/prices');
  if (response?.data?.success) return response?.data?.prices;
  else return false;
}

async function createPrice(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.post('/admin/prices', data);
  if (response?.data?.success) return response?.data?.price;
  else return false;
}

async function updatePrice(site_id, price_id, data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.put(`/admin/prices/${site_id}/${price_id}`, data);
  if (response?.data?.success) return true;
  else return false;
}

async function deletePrice(site_id, price_id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`,
    },
  });
  const response = await instance.delete(`/admin/prices/${site_id}/${price_id}`);
  if (response?.data?.success) return true;
  else return false;
}

/***********  Messaging functions ***********/

// Get all conversations for a client
async function getConversations() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/ai/messaging/conversations`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.conversations;
}

// Get a single conversation
async function getConversation(contact) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/ai/messaging/conversations/${contact}`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.messages;
}

// Send a message
async function sendMessage(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/ai/messaging/send`, data);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

/***********  Info functions ***********/

// Get all faqs
async function getInfo() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.get(`/admin/info`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.info;
}

// Add an faq
async function addInfo(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.post(`/admin/info`, data);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

// Delete an faq
async function deleteInfo(info_id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  });
  const response = await instance.delete(`/admin/info/${info_id}`);
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false
  } else return true;
}

/***********  Channel Functions ***********/

// Get WhatsApp instance
async function getInstance() {
  const response = await axios.get(`${url}/channels/whatsapp`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  })
  return response.data;
}

// Create a new WhatsApp instance
async function createInstance() {
  const response = await axios.post(`${url}/channels/whatsapp`, {}, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error creating WhatsApp instance. Please try again or contact support.');
  });
  return response.data;
}

// Delete a WhatsApp instance
async function disconnectInstance() {
  const response = await axios.delete(`${url}/channels/whatsapp`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error disconnecting WhatsApp instance. Please try again or contact support.');
  });
  return response.data;
}

// Request a new SMS instance
async function requestSMSInstance(data) {
  const response = await axios.post(`${url}/channels/sms/request`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.adminSession}`
    },
  }).catch(err => {
    console.log(err);
    alert('Error requesting SMS instance. Please try again or contact support.');
  });
  return response.data;
}

export {
  login,
  register,
  createSite,
  updateSite,
  getSites,
  getClientSettings,
  updateClientSettings,
  checkIntegrations,
  updateIntegrations,
  requestAIAccess,
  getBookings,
  getAttendees,
  markAttendance,
  addNote,
  findUser,
  searchUsers,
  addPackage,
  removePackage,
  addVisits,
  updateUser,
  toggleSubscription,
  toggleVerification,
  toggleAdmin,
  getAnalytics,
  createSchedule,
  deleteSchedule,
  getSchedule,
  createService,
  deleteService,
  setOpeningTimes,
  addClosure,
  addBookables,
  removeBookables,
  editBookable,
  exportBookings,
  getMessages,
  addData,
  getData,
  removeData,
  getPrices,
  createPrice,
  updatePrice,
  deletePrice,
  getConversations,
  getConversation,
  sendMessage,
  getInfo,
  addInfo,
  deleteInfo,
  getInstance,
  createInstance,
  disconnectInstance,
  requestSMSInstance,
};